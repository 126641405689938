import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Image, Link } from '@/components/atoms';
import styles from './brandLogoSlider.module.scss';

const BrandsLogoSlider = ({ items, noLinks }) => {
  const [key, setKey] = useState(false);

  // Safari necesita nuevo renderizado
  useEffect(() => {
    setKey(!key);
  }, [items]);

  return (
    <div
      key={key}
      className={styles.container}
    >
      <div className={styles.slider}>
        {items.map((i) => (
          <div
            className={styles.item}
            key={i.name}
          >
            {noLinks ? (
              <div className={styles.img}>
                <Image
                  alt={i.name}
                  src={i.brand_icon?.url || i.brand_icon_white?.url}
                  width={90}
                  height={40}
                  heightSkeleton="40px"
                  widthSkeleton="100px"
                  skeletonDark
                  cover
                />
              </div>
            ) : (
              <Link
                href={`/${i.url}${i.url.includes('/p/1') ? '' : '/p/1'}`}
                noWidth
              >
                <div className={styles.img}>
                  <Image
                    alt={i.name}
                    src={i.brand_icon?.url || i.brand_icon_white?.url}
                    width={90}
                    height={40}
                    heightSkeleton="40px"
                    widthSkeleton="100px"
                    skeletonDark
                    cover
                  />
                </div>
              </Link>
            )}
          </div>
        ))}
      </div>
      <div className={styles.slider}>
        {items.map((i) => (
          <div
            className={styles.item}
            key={i.name}
          >
            {noLinks ? (
              <div className={styles.img}>
                <Image
                  alt={i.name}
                  src={i.brand_icon?.url || i.brand_icon_white?.url}
                  width={90}
                  height={40}
                  heightSkeleton="40px"
                  widthSkeleton="100px"
                  skeletonDark
                  cover
                />
              </div>
            ) : (
              <Link
                href={`/${i.url}${i.url.includes('/p/1') ? '' : '/p/1'}`}
                noWidth
              >
                <div className={styles.img}>
                  <Image
                    alt={i.name}
                    src={i.brand_icon?.url || i.brand_icon_white?.url}
                    width={90}
                    height={40}
                    heightSkeleton="40px"
                    widthSkeleton="100px"
                    skeletonDark
                    cover
                  />
                </div>
              </Link>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

BrandsLogoSlider.propTypes = {
  items: PropTypes.array.isRequired,
  noLinks: PropTypes.bool
};
BrandsLogoSlider.defaultProps = {
  noLinks: false
};

export default BrandsLogoSlider;
